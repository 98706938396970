import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

import type { StrapiFormStubBannerFormatted } from "components/form-stub/formStubBanner-types"
import type {
  GeneralSettingsContextData,
  GeneralSettingsProviderProps,
  GraphQLContextData,
} from "./generalSettings-types"

const GeneralSettingsContext = createContext<GeneralSettingsContextData>(
  {} as GeneralSettingsContextData
)

const GeneralSettingsProvider = ({
  children,
}: GeneralSettingsProviderProps) => {
  const data = useStaticQuery<GraphQLContextData>(getEnvs)
  const {
    company_name,
    company_email,
    custom_call_to_action,
    custom_tracking_number_cta_button_text,
    custom_tracking_number_modal_heading,
    offices,
    formal_company_name,
    tracking_number,
    tracking_number_label,
    tracking_number_svg_icon,
    use_phone_number_not_tracking_number,
  } = data.allStrapiGeneral?.nodes[0]
  const { blogParent, domainVerification, recapKey, siteUrl } = data?.nodeEnvs

  const {
    custom_css_code,
    custom_style_sheet,
    script: scripts,
  } = data.allStrapiCustom.nodes[0]

  const { additional_footer_information, footer_background_image } =
    data.allStrapiFooterCustomization?.nodes[0]
  const { enable_alert_banner, alert_banner } = data.allStrapiAlert.nodes[0]
  const { global_form_stub_banner } = data.allStrapiGlobalComponent.nodes[0]
  const { social_media } = data.allStrapiSocialMedia.nodes[0]

  const companyName = company_name ?? ""
  const customCSSUrl = custom_style_sheet?.url ?? ""
  const customCTAText = custom_call_to_action ?? ""
  const formalCompanyName = formal_company_name ?? ""
  const isAlertEnabled = enable_alert_banner

  const footerAdditionalInformation =
    additional_footer_information?.data?.additional_footer_information ?? ""

  const alertBanner = alert_banner && {
    alert_text: alert_banner.alert_text ?? "",
    background_color: alert_banner.background_color ?? "",
    svg_icon: alert_banner.svg_icon ?? "",
    id: alert_banner.id,
    link: alert_banner.link ?? "",
  }

  const socialMedia =
    social_media?.map(socialMediaItem => {
      return {
        customIcon: socialMediaItem.custom_outlet_svg ?? "",
        customOutletName: socialMediaItem.custom_outlet ?? "",
        id: socialMediaItem.id,
        linkUrl: socialMediaItem.link ?? "",
        mediaOutlet: socialMediaItem.social_media_outlet || "custom",
      }
    }) ?? []

  const customTrackingNumberCtaButtonText =
    custom_tracking_number_cta_button_text ?? ""
  const customTrackingNumberModalHeading =
    custom_tracking_number_modal_heading ?? ""
  const usePhoneNumberNotTrackingNumber = use_phone_number_not_tracking_number

  const trackingNumbers = offices?.map(officeItem => {
    return {
      id: officeItem.id,
      label: officeItem.tracking_number_label ?? "",
      phoneNumber: officeItem.phone_number ?? "",
      svgIcon: officeItem.tracking_number_svg_icon ?? "",
      trackingNumber: officeItem.tracking_number ?? "",
    }
  })

  const formattedOffices =
    offices?.map(officeItem => {
      return {
        id: officeItem.id,
        name: officeItem.office_name ?? "",
        address_line_1: officeItem.address_line_1 ?? "",
        address_line_2: officeItem.address_line_2 ?? "",
        city: officeItem.city ?? "",
        state: officeItem.state ?? "",
        zip: officeItem.zip ?? "",
        email: officeItem.office_email ?? "",
        phone: officeItem.phone_number ?? "",
        priceRange: officeItem.price_range ?? "",
        businessHours: officeItem.office_hours ?? undefined,
        simpleOfficeHours: officeItem.simple_office_hours ?? "",
        simpleServiceHours: officeItem.simple_service_hours ?? "",
      }
    }) ?? []

  const footerBackgroundImage = footer_background_image?.url ?? ""

  const globalFormStubBanner = global_form_stub_banner
    ? ({
        heading: global_form_stub_banner.heading || "",
        sub_heading: global_form_stub_banner?.sub_heading || "",
        button: global_form_stub_banner?.button || undefined,
        icon: global_form_stub_banner?.icon || undefined,
        fields: global_form_stub_banner?.form_fields || [],
      } as StrapiFormStubBannerFormatted)
    : undefined

  return (
    <GeneralSettingsContext.Provider
      value={{
        alertBanner,
        blogParent,
        companyName,
        customCSSUrl,
        customCTAText,
        customTrackingNumberCtaButtonText,
        customTrackingNumberModalHeading,
        domainVerification,
        footerAdditionalInformation,
        footerBackgroundImage,
        formalCompanyName,
        formattedOffices,
        globalFormStubBanner,
        isAlertEnabled,
        recapKey,
        scripts,
        siteUrl,
        socialMedia,
        trackingNumbers,
        usePhoneNumberNotTrackingNumber,
      }}
    >
      {children}

      {custom_css_code && <style> {custom_css_code} </style>}
    </GeneralSettingsContext.Provider>
  )
}

export const getEnvs = graphql`
  query GeneralSettingsContext {
    allStrapiGeneral {
      nodes {
        company_email
        company_name
        formal_company_name
        custom_call_to_action
        custom_tracking_number_cta_button_text
        custom_tracking_number_modal_heading
        use_phone_number_not_tracking_number
        offices {
          id
          office_name
          office_email
          phone_number
          tracking_number_label
          tracking_number
          tracking_number_svg_icon
          short_description
          address_line_1
          address_line_2
          city
          state
          zip
          simple_office_hours
          simple_service_hours
          office_hours {
            monday_open
            monday_close
            tuesday_open
            tuesday_close
            wednesday_open
            wednesday_close
            thursday_open
            thursday_close
            friday_open
            friday_close
            saturday_open
            saturday_close
            sunday_open
            sunday_close
          }
        }
      }
    }
    allStrapiCustom {
      nodes {
        custom_css_code
        custom_style_sheet {
          url
        }
        script {
          src_value
          whole_script
        }
      }
    }
    allStrapiFooterCustomization {
      nodes {
        additional_footer_information {
          data {
            additional_footer_information
          }
        }
        footer_background_image {
          alternativeText
          url
        }
      }
    }
    allStrapiGlobalComponent {
      nodes {
        ...StrapiFormStubBannerFragment
      }
    }
    allStrapiSocialMedia {
      nodes {
        ...StrapiSocialMediaFragment
      }
    }
    allStrapiAlert {
      nodes {
        ...StrapiAlertBannerFragment
      }
    }
    nodeEnvs {
      siteUrl
      recapKey
      blogParent
      domainVerification
    }
  }
`
export { GeneralSettingsProvider, GeneralSettingsContext }
