import React from "react"
import { AppProvider } from "./src/contexts"
import "./src/assets/scss/main.scss"

import "@fontsource-variable/montserrat"
import "@fontsource-variable/montserrat/wght-italic.css"
import "@fontsource-variable/open-sans"
import "@fontsource-variable/open-sans/wght-italic.css"

export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}
