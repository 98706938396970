module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BugOut Pest","short_name":"BugOut Pest","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"../site/src/images/placeholder-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"952b5c53ead53c40f1fd5700d59f673c"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bugoutpestaz.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MMRKQZM","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../../gatsby-theme-baxter/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"8cfea234-ffea-00f3-2a30-981c5a7c0b10","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImM1MWVkZTIxMDE3YjQyMGI5NmMzODljNzE2M2U5NjU4IiwNCiAgImlhdCI6ICIxNjYwNTcxOTI5IiwNCiAgImV4cCI6ICIyMDA2MTcxOTI5IiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogIjhjZmVhMjM0ZmZlYTAwZjMyYTMwOTgxYzVhN2MwYjEwIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.z7bI_dzyrxTSebkhCft9il4lVMlIYkoKqAlFsY0AIQQ","usePreviewUrl":"false","siteUrl":"https://www.bugoutpestaz.com","title":"BugOut Pest","description":"BugOut Pest","domainVerification":"eL6-Y6SAPeI6D8AB18JZD4sARhi6VP3_EZQ8caomDLs","gtmId":"GTM-MMRKQZM","whereWeServicePageSlug":"where-we-service","whereWeServiceBreadcrumbLabel":"Where We Service"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
