import React, { createContext, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import type {
  GraphQLContextData,
  IntegrationsSettingsContextData,
  IntegrationsSettingsProviderProps,
  StaticReviewBannerOutput,
  StaticReviewOutput,
} from "./integrationsSettings-types"

const IntegrationsSettingsContext =
  createContext<IntegrationsSettingsContextData>(
    {} as IntegrationsSettingsContextData
  )

const IntegrationsSettingsProvider = ({
  children,
}: IntegrationsSettingsProviderProps) => {
  const data = useStaticQuery<GraphQLContextData>(getIntegrationsSettinsData)

  const {
    custom_link_text_fieldroutes_portal,
    fieldroutes_reviews,
    google_reviews_url,
    enable_fieldroutes_portal,
    static_reviews,
    review_image,
    fieldroutes_review_custom_link_text,
    fieldroutes_reviews_custom_eyebrow_text,
    buy_online_get_started_button,
  } = data.allStrapiIntegration?.nodes[0]

  const integrationButton = buy_online_get_started_button
  const hasFieldroutesReviews = fieldroutes_reviews
  const customLinkTextFieldroutesReviews =
    fieldroutes_review_custom_link_text ?? ""

  const fieldroutesReviewImages =
    review_image?.map(review => {
      return {
        id: review.id,
        single_image: review.image,
      }
    }) ?? []

  const hasFieldroutesPortal = enable_fieldroutes_portal
  const customLinkTextFieldroutesPortal =
    custom_link_text_fieldroutes_portal ?? ""
  const googleReviewsUrl = google_reviews_url ?? undefined

  const staticReviewsBanner = static_reviews
    ? ({
        eyebrow_text: static_reviews?.eyebrow_text ?? "",
        heading: static_reviews?.heading ?? "",
        sub_heading: static_reviews?.sub_heading ?? "",
        background_image: static_reviews?.background_image ?? undefined,
        logos: static_reviews?.logos ?? [],
      } as StaticReviewBannerOutput)
    : undefined

  const staticReviews: StaticReviewOutput[] = useMemo(() => {
    return (
      static_reviews?.reviews?.map(review => {
        return {
          eyebrowText: review?.eyebrow_text ?? "",
          heading: review?.heading ?? "",
          location: review?.location ?? "",
          rating: review?.rating ?? "",
          reviewSourceLink: review?.review_source_link ?? "",
          reviewer: review?.reviewer_name ?? "",
          review: review?.review_text?.data?.review_text ?? "",
          reviewBackgroundImage: review?.review_background_image ?? undefined,
          reviewImage: review?.review_image ?? undefined,
        }
      }) ?? []
    )
  }, [static_reviews?.reviews])

  const fieldroutesReviewBackgroundImage = staticReviewsBanner?.background_image

  const customHeadingFieldroutesReviews =
    staticReviewsBanner?.heading ?? "Customer Reviews"

  const customEyebrowTextFieldroutesReviews =
    fieldroutes_reviews_custom_eyebrow_text ?? ""

  return (
    <IntegrationsSettingsContext.Provider
      value={{
        customHeadingFieldroutesReviews,
        customEyebrowTextFieldroutesReviews,
        hasFieldroutesReviews,
        fieldroutesReviewImages,
        hasFieldroutesPortal,
        googleReviewsUrl,
        staticReviewsBanner,
        staticReviews,
        fieldroutesReviewBackgroundImage,
        customLinkTextFieldroutesReviews,
        customLinkTextFieldroutesPortal,
        integrationButton,
      }}
    >
      {children}
    </IntegrationsSettingsContext.Provider>
  )
}

export const staticReviewsFragment = graphql`
  fragment StaticReviewsFragment on STRAPI__COMPONENT_REVIEWS_REVIEW_BLOCK {
    eyebrow_text
    heading
    background_image {
      alternativeText
      ...StrapiImageFragment
    }
    logos {
      image {
        alternativeText
        ...StrapiImageFragment
      }
    }
    reviews {
      date
      eyebrow_text
      heading
      location
      rating
      review_background_image {
        alternativeText
        ...StrapiImageFragment
      }
      review_image {
        alternativeText
        ...StrapiImageFragment
      }
      review_source_link
      review_text {
        data {
          review_text
        }
      }
      reviewer_name
    }
    sub_heading
  }
`

export const fieldRoutesReviewImagesFragment = graphql`
  fragment FieldRoutesReviewImagesFragment on STRAPI__COMPONENT_IMAGE_VARIATIONS_IMAGE {
    id
    title
    caption
    external_link
    page {
      slug
    }
    image {
      ...StrapiImageFragment
    }
  }
`

export const getIntegrationsSettinsData = graphql`
  query IntegrationsSettingsContext {
    allStrapiIntegration {
      nodes {
        buy_online_get_started_button {
          ...StrapiButtonFragment
        }
        custom_link_text_fieldroutes_portal
        enable_fieldroutes_portal
        fieldroutes_review_custom_link_text
        fieldroutes_reviews
        fieldroutes_reviews_custom_eyebrow_text
        google_reviews_url
        review_image {
          ...FieldRoutesReviewImagesFragment
        }
        static_reviews {
          ...StaticReviewsFragment
        }
      }
    }
  }
`

export { IntegrationsSettingsProvider, IntegrationsSettingsContext }
