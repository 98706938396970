import React from "react"
import { useState, createContext, useCallback, ReactNode } from "react"

export type SideNavContextData = {
  handleCloseNav: () => void
  handleOpenNav: () => void
  handleToggleNav: (tab: string) => void
  isNavOpen: boolean
  activeTab: string
  activeSubNavId: string
  handleToggleSubNav: (id: string) => void
}

interface SideNavProviderProps {
  children: ReactNode
}

const SideNavContext = createContext<SideNavContextData>(
  {} as SideNavContextData
)

const SideNavProvider = ({ children }: SideNavProviderProps) => {
  // activeTab was used with the meganav
  const [activeTab, setActiveTab] = useState("")
  const [isNavOpen, setNav] = useState(false)
  const [activeSubNavId, setActiveSubNavId] = useState("")

  const handleToggleSubNav = useCallback((id: string)=>{
    setActiveSubNavId(state => state !== id ? id : '')
  }, [activeSubNavId])

  const handleToggleNav = useCallback(
    (tab: string) => {
      if (tab === activeTab) {
        setActiveTab("")
        setNav(false)
      } else if (tab) {
        setActiveTab(tab)
        setNav(true)
      }
    },
    [activeTab]
  )

  const handleOpenNav = useCallback(() => {
    setNav(true)
  }, [])

  const handleCloseNav = useCallback(() => {
    setActiveTab("")
    setNav(false)
  }, [])

  return (
    <SideNavContext.Provider
      value={{
        handleCloseNav,
        handleOpenNav,
        handleToggleNav,
        isNavOpen,
        activeTab,
        handleToggleSubNav,
        activeSubNavId
      }}
    >
      {children}
    </SideNavContext.Provider>
  )
}

export { SideNavProvider, SideNavContext }
