import React from "react"
import { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  ColorArea,
  ColorAreasSettingsContextData,
  ColorAreasSettingsProviderProps,
  GraphQLContextData,
} from "./colorAreasSettings-types"

const ColorAreasSettingsContext = createContext<ColorAreasSettingsContextData>(
  {} as ColorAreasSettingsContextData
)

type CssVariableColors = {
  primary: "fr-primary-overwrite"
  primary_alt: "fr-primaryAlt-overwrite"
  secondary: "fr-secondary-overwrite"
  secondary_alt: "fr-secondaryAlt-overwrite"
  accent: "fr-accent-overwrite"
  accent_alt: "fr-accentAlt-overwrite"
  gray: "fr-link-overwrite"
}

const COLOR_CSS_VARIABLE: CssVariableColors = {
  primary: "fr-primary-overwrite",
  primary_alt: "fr-primaryAlt-overwrite",
  secondary: "fr-secondary-overwrite",
  secondary_alt: "fr-secondaryAlt-overwrite",
  accent: "fr-accent-overwrite",
  accent_alt: "fr-accentAlt-overwrite",
  gray: "fr-link-overwrite",
}

const ColorAreasSettingsProvider = ({
  children,
}: ColorAreasSettingsProviderProps) => {
  const data = useStaticQuery<GraphQLContextData>(getColorAreasSettingsData)

  const { allStrapiColorArea } = data

  const {
    image_banner_color_overlay,
    nav_tile_color_overlay,
    side_nav_background_color,
    text_banner_background_color,
  } = allStrapiColorArea.nodes[0]

  const getCSSVariableColor = (color: ColorArea | "") => {
    if (!color) return color
    return COLOR_CSS_VARIABLE[color]
  }

  const imageBannerColorOverlay = image_banner_color_overlay ?? ""
  const navTileColorOverlay = nav_tile_color_overlay ?? ""
  const sideNavBackgroundColor = side_nav_background_color ?? ""
  const textBannerBackgroundColor = text_banner_background_color ?? ""

  return (
    <ColorAreasSettingsContext.Provider
      value={{
        imageBannerColorOverlay: getCSSVariableColor(imageBannerColorOverlay),
        textBannerBackgroundColor: getCSSVariableColor(
          textBannerBackgroundColor
        ),
        navTileColorOverlay: getCSSVariableColor(navTileColorOverlay),
        sideNavBackgroundColor: getCSSVariableColor(sideNavBackgroundColor),
      }}
    >
      {children}
    </ColorAreasSettingsContext.Provider>
  )
}

export const getColorAreasSettingsData = graphql`
  query ColorAreasSettingsContext {
    allStrapiColorArea {
      nodes {
        image_banner_color_overlay
        nav_tile_color_overlay
        side_nav_background_color
        text_banner_background_color
      }
    }
  }
`

export { ColorAreasSettingsProvider, ColorAreasSettingsContext }
